import moment from 'moment'
import { ServiceFactory } from '@/services/ServiceFactory'
const SubscribersService = ServiceFactory.get('SubscribersService')

export default {
  name: 'ZbSubscribers',
  data () {
    return {
      tab: null,
      search: '',
      loading: false,
      dialog: false,
      dialogDel: false,
      defaultImage: `${process.env.BASE_URL}images/user-default.png`,
      subscribersItems: [],
      headers: [
        {
          text: this.$t('subscribers.image'),
          align: 'left',
          value: 'client.image',
          sortable: false
        },
        {
          text: this.$t('subscribers.subscribe'),
          value: 'client.name',
          sortable: false
        },
        {
          text: this.$t('subscribers.cellphone'),
          value: 'client.cellphone',
          sortable: false
        },
        {
          text: this.$t('subscribers.startdate'),
          value: 'subscriptionStartDate',
          sortable: false
        },
        {
          text: this.$t('subscribers.finishdate'),
          value: 'subscriptionEndDate',
          sortable: false
        },
        {
          text: this.$t('subscribers.subscriptiontime'),
          value: 'subscriptionTime',
          sortable: false
        }
      ],
      // pagination
      itemsPerPage: 10,
      page: 1,
      options: {
        totalItems: 0,
        totalPages: 0
      }
    }
  },
  watch: {
    tab () {
      this.getSubscribersEvents()
    },
    page () {
      this.getSubscribersEvents()
    },
    itemsPerPage () {
      this.getSubscribersEvents()
    }
  },
  methods: {
    subscriptionTimeEvent (start, finish) {
      const startdate = moment(start)
      const finishdate = moment(finish)
      const daysSubscription = finishdate.diff(startdate, 'days')
      return daysSubscription
    },
    getSubscribersEvents () {
      switch (this.tab) {
        case 0:
          this.getSubscribers(true)
          break
        case 1:
          this.getSubscribers(false)
          break
        default:
          this.getSubscribers(true)
          break
      }
    },
    async getSubscribers (active) {
      try {
        this.subscribersItems = []
        this.loading = true
        const { subscriptions, totalItems, totalPages } = await SubscribersService.getSubscribers(active, this.page, this.itemsPerPage)
        this.subscribersItems = await subscriptions
        this.subscribersItems = this.subscribersItems.map((v, k) => {
          const days = this.subscriptionTimeEvent(v.subscriptionStartDate, v.subscriptionEndDate)
          return {
            ...this.subscribersItems[k],
            subscriptionTime: days
          }
        })
        this.options = {
          totalItems: await totalItems,
          totalPages: await totalPages
        }
        this.loading = false
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
